.main {

}

.main-content {
    display: block;
    margin-top: 40px;
}

.one-quarter, 
.two-eighths, 
.three-twelfths {
    width: 25%;
}

hr {
    clear: both;
    border-top: solid #ebebeb;
    border-width: 1px 0 0;
    margin: 50px 0;
    height: 0;
}

.grid-item {
    float: left;
    min-height: 1px;
    padding-left: 24px;
    vertical-align: top;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.feature-homepage-content .rte {
    margin-bottom: 50px;
}