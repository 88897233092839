.site-header .wrapper {
    padding: 0 20px;
}

.wrapper {
    max-width: 955px;
    margin: 0 auto;
    padding: 0 25px;
}

.header-logo {
    text-align: center;
    margin: 0 auto 12.5px;
    max-width: 200px;
}

.header-logo img {
    margin: 0 auto;
}

.header-logo a, 
.header-logo img {
    display: block;
}


img.auto, .grid-item img, 
.grid-item iframe {
    max-width: 100%;
}

.nav-bar {
    /* top: 110%; */
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #60b6cd;
    font-family: "Montserrat","HelveticaNeue","Helvetica Neue",sans-serif;
    text-transform: uppercase;
}

.site-nav {
    margin: 0;
    padding: 0;
    font-size: 13px;
    cursor: default;
    text-align: center;
}

.site-nav li {
    margin: 0;
    display: block;
}

.site-nav>li {
    position: relative;
    display: inline-block;
}

.site-nav a {
    display: block;
    text-decoration: none;
    padding: 0 16px;
    line-height: 53px;
    white-space: nowrap;
    letter-spacing: 1px;
    font-weight: 700;
}

.nav-bar a {
    color: #fff;
}

.site-header .grid-item {
    padding-left: 0px;
}