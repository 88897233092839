* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}


body, 
input, 
textarea, 
button, select {
    font-size: 15px;
    line-height: 1.6;
    font-family: "Open Sans","HelveticaNeue","Helvetica Neue",sans-serif;
    color: dimgrey;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}

[class*="push--"] {
    position: relative;
}

a {
    color: #1b9bce;
    text-decoration: none;
    background: transparent;
}

.text-center {
    text-align: center !important;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: "Lora",serif;
    font-weight: 400;
    color: #403f3f;
    margin: 0 0 0.5em;
    line-height: 1.4;
}

h1, .h1 {
    font-size: 2.46667em !important;
}

h2, .h2 {
    font-size: 1.86869em;
}



.rte h1, .rte .h1, .rte h2, .rte .h2, .rte h3, .rte .h3, .rte h4, .rte .h4, .rte h5, .rte .h5, .rte h6, .rte .h6 {
    margin-top: 2em;
    text-align: center;
}

.rte h2, .rte .h2 {
    margin-top: 70px;
    margin-bottom: 35px;
}

.rte p {
    margin-bottom: 25px;
}


p {
    margin: 0 0 12.5px 0;
}
b, strong {
    font-weight: 700 !important;
}

img.auto, 
.grid-item img, 
.grid-item iframe {
    max-width: 100%;
}


.grid, 
.grid--rev, 
.grid--full, 
.grid-uniform {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: -24px;
}



.grid--full {
    margin-left: 0;
}
.grid--full>.grid-item {
    padding-left: 0;
}






.rte h1:first-child, 
.rte .h1:first-child, 
.rte h2:first-child, 
.rte .h2:first-child, 
.rte h3:first-child, 
.rte .h3:first-child, 
.rte h4:first-child, 
.rte .h4:first-child, 
.rte h5:first-child, 
.rte .h5:first-child, 
.rte h6:first-child, 
.rte .h6:first-child {
    margin-top: 0;
}