@media only screen and (min-width:320px) {

}

@media only screen and (max-width:480px) {

 .header-logo {
        margin: 25px auto 12.5px;
    }

    .site-header{
        height: 150px;
    }
    
}

@media only screen and (max-width: 768px) and (min-width: 481px){

    .medium--hide {
        display: none !important;
    }

    
    .medium--one-half, 
    .medium--two-quarters, 
    .medium--three-sixths, 
    .medium--four-eighths, 
    .medium--five-tenths, 
    .medium--six-twelfths {
    width: 50%;
    }
    
}

@media only screen and (min-width:480px) {
    
    .site-header {
        padding: 25px 0;
    }

    .site-header .grid, 
    .site-header .grid--rev, 
    .site-header .grid--full {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .visible--small {
        display: none;
    }

    .site-header .grid>.grid-item, 
    .site-header .grid--rev>.grid-item, 
    .site-header .grid--full>.grid-item {
        float: none;
        display: table-cell;
        vertical-align: middle;
    }  
    
    .rte .banner-img {
    width: 120%;
    max-width: 120%;
    margin-left: -10%;
    margin-right: -10%;
}
    
.rte img:not([style]) {
    display: block;
    margin: 0 auto;
}
}

@media only screen and (min-width:769px) {
    
    .large--hide {
        display: none !important;
    }

        .nav-bar {
        margin: 0 auto;
        border-radius: 3px;
    }

        .site-nav {
        text-align: center;
        white-space: nowrap;
        margin-left: -12.5px;
    }


        .site-nav>li {
        position: relative;
        display: inline-block;
    }

        .site-nav a {
        white-space: nowrap;
    }

        .large--one-half, 
        .large--two-quarters, 
        .large--three-sixths, 
        .large--four-eighths, 
        .large--five-tenths, 
        .large--six-twelfths {
        width: 50%;
    }

        .header-logo {
        margin-bottom: 0;
    }
    
    
    .push--large--one-sixth, 
    .push--large--two-twelfths {
        left: 16.666%;
    }
    
    .large--two-thirds, 
    .large--four-sixths, 
    .large--eight-twelfths {
    width: 66.666%;
    }
    
    .large--eight-twelfths, 
    .large--four-sixths, 
    .large--two-thirds {
    width: 66.666%;
    }
    .rte {
    margin-bottom: 12.5px;
    }
    
}

@media only screen and (min-width:1024px) {

}
@media only screen and (min-width:1140px) {

}
@media only screen and (min-width:1280px) {

}
@media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {

}
